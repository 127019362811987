import { createApp } from 'vue'
import { createPinia } from 'pinia'

import 'virtual:uno.css'
import '@unocss/reset/tailwind.css'
import 'element-plus/es/components/message-box/style/css'

import '@/preset'

import App from '@/app.vue'
import router from '@/router'

const app = createApp(App)
const pinia = createPinia()

app.use(router)
app.use(pinia)

app.config.globalProperties.CREATE = CREATE
app.config.globalProperties.UPDATE = UPDATE

async function bootstrap() {
  await router.isReady()
  app.mount('#app')
}

bootstrap()

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/agent',
    /* internal name: '/agent' */
    /* no component */
    children: [
      {
        path: '',
        name: '/agent/',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/agent/index.vue'),
        /* no children */
      },
      {
        path: 'setting-:agentId',
        name: '/agent/setting-[agentId]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/agent/setting-[agentId].vue'),
        children: [
          {
            path: 'account',
            name: '/agent/setting-[agentId]/account',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/agent/setting-[agentId]/account.vue'),
            /* no children */
          },
          {
            path: 'certification',
            name: '/agent/setting-[agentId]/certification',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/agent/setting-[agentId]/certification.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/audit',
    /* internal name: '/audit' */
    /* no component */
    children: [
      {
        path: 'agent',
        name: '/audit/agent',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/agent.vue'),
        /* no children */
      },
      {
        path: 'agent-:id',
        name: '/audit/agent-[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/agent-[id].vue'),
        children: [
          {
            path: 'account',
            name: '/audit/agent-[id]/account',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/agent-[id]/account.vue'),
            /* no children */
          },
          {
            path: 'certification',
            name: '/audit/agent-[id]/certification',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/agent-[id]/certification.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'refund',
        name: '/audit/refund',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/refund.vue'),
        /* no children */
      },
      {
        path: 'refund-:id',
        name: '/audit/refund-[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/refund-[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/audit/refund-[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/refund-[id]/basic.vue'),
            /* no children */
          },
          {
            path: 'log',
            name: '/audit/refund-[id]/log',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/refund-[id]/log.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'sign',
        name: '/audit/sign',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/sign.vue'),
        /* no children */
      },
      {
        path: 'sign-:id',
        name: '/audit/sign-[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/sign-[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/audit/sign-[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/sign-[id]/basic.vue'),
            /* no children */
          },
          {
            path: 'log',
            name: '/audit/sign-[id]/log',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/audit/sign-[id]/log.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/business',
    /* internal name: '/business' */
    /* no component */
    children: [
      {
        path: 'appointment',
        name: '/business/appointment',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/appointment.vue'),
        /* no children */
      },
      {
        path: 'appointment/:id',
        name: '/business/appointment.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/appointment.[id].vue'),
        /* no children */
      },
      {
        path: 'c-user',
        name: '/business/c-user',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.vue'),
        /* no children */
      },
      {
        path: 'c-user/:id',
        name: '/business/c-user.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.[id].vue'),
        children: [
          {
            path: 'appointment',
            name: '/business/c-user.[id]/appointment',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.[id]/appointment.vue'),
            /* no children */
          },
          {
            path: 'basic',
            name: '/business/c-user.[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.[id]/basic.vue'),
            /* no children */
          },
          {
            path: 'favorite',
            name: '/business/c-user.[id]/favorite',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.[id]/favorite.vue'),
            /* no children */
          },
          {
            path: 'order',
            name: '/business/c-user.[id]/order',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.[id]/order.vue'),
            /* no children */
          },
          {
            path: 'reward',
            name: '/business/c-user.[id]/reward',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.[id]/reward.vue'),
            /* no children */
          },
          {
            path: 'trajectory',
            name: '/business/c-user.[id]/trajectory',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/c-user.[id]/trajectory.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'order',
        name: '/business/order',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/order.vue'),
        /* no children */
      },
      {
        path: 'report',
        name: '/business/report',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/report.vue'),
        /* no children */
      },
      {
        path: 'report/:id',
        name: '/business/report.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/report.[id].vue'),
        /* no children */
      },
      {
        path: 'sign',
        name: '/business/sign',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/sign.vue'),
        /* no children */
      },
      {
        path: 'sign-:id',
        name: '/business/sign-[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/sign-[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/business/sign-[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/sign-[id]/basic.vue'),
            /* no children */
          },
          {
            path: 'log',
            name: '/business/sign-[id]/log',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/sign-[id]/log.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'trajectory',
        name: '/business/trajectory',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/trajectory.vue'),
        /* no children */
      },
      {
        path: 'visit',
        name: '/business/visit',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/visit.vue'),
        /* no children */
      },
      {
        path: 'visit-:id',
        name: '/business/visit-[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/business/visit-[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/demo',
    name: '/demo',
    component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/demo.vue'),
    /* no children */
  },
  {
    path: '/finance',
    /* internal name: '/finance' */
    /* no component */
    children: [
      {
        path: 'invite',
        name: '/finance/invite',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/invite.vue'),
        /* no children */
      },
      {
        path: 'invite/:id',
        name: '/finance/invite.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/invite.[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/finance/invite.[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/invite.[id]/basic.vue'),
            /* no children */
          },
          {
            path: 'log',
            name: '/finance/invite.[id]/log',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/invite.[id]/log.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'kickback',
        name: '/finance/kickback',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/kickback.vue'),
        /* no children */
      },
      {
        path: 'kickback/:id',
        name: '/finance/kickback.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/kickback.[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/finance/kickback.[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/kickback.[id]/basic.vue'),
            /* no children */
          },
          {
            path: 'log',
            name: '/finance/kickback.[id]/log',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/kickback.[id]/log.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'pay',
        name: '/finance/pay',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/pay.vue'),
        /* no children */
      },
      {
        path: 'rewards',
        name: '/finance/rewards',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/rewards.vue'),
        /* no children */
      },
      {
        path: 'rewards/:id',
        name: '/finance/rewards.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/rewards.[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/finance/rewards.[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/finance/rewards.[id]/basic.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/invite',
    /* internal name: '/invite' */
    /* no component */
    children: [
      {
        path: 'config',
        name: '/invite/config',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/invite/config.vue'),
        children: [
          {
            path: ':id',
            name: '/invite/config/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/invite/config/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'detail',
        name: '/invite/detail',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/invite/detail.vue'),
        /* no children */
      },
      {
        path: 'detail-:id',
        name: '/invite/detail-[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/invite/detail-[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/invite/detail-[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/invite/detail-[id]/basic.vue'),
            /* no children */
          },
          {
            path: 'log',
            name: '/invite/detail-[id]/log',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/invite/detail-[id]/log.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "full-screen"
    }
  },
  {
    path: '/market',
    /* internal name: '/market' */
    /* no component */
    children: [
      {
        path: 'banner',
        name: '/market/banner',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/market/banner.vue'),
        /* no children */
      },
      {
        path: 'banner/editor/:id',
        name: '/market/banner.editor.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/market/banner.editor.[id].vue'),
        /* no children */
      },
      {
        path: 'news',
        name: '/market/news',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/market/news.vue'),
        /* no children */
      },
      {
        path: 'news/editor/:id',
        name: '/market/news.editor.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/market/news.editor.[id].vue'),
        /* no children */
      },
      {
        path: 'quick-link',
        name: '/market/quick-link',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/market/quick-link.vue'),
        /* no children */
      },
      {
        path: 'quick-link/:id',
        name: '/market/quick-link.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/market/quick-link.[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/payment',
    /* internal name: '/payment' */
    /* no component */
    children: [
      {
        path: 'invite',
        name: '/payment/invite',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/payment/invite.vue'),
        /* no children */
      },
      {
        path: 'kickback',
        name: '/payment/kickback',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/payment/kickback.vue'),
        /* no children */
      },
      {
        path: 'reward',
        name: '/payment/reward',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/payment/reward.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/project',
    /* internal name: '/project' */
    /* no component */
    children: [
      {
        path: 'city',
        name: '/project/city',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/city.vue'),
        children: [
          {
            path: ':id',
            name: '/project/city/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/city/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'city-setting-:urbanPlateId',
        name: '/project/city-setting-[urbanPlateId]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/city-setting-[urbanPlateId].vue'),
        /* no children */
        meta: {
          "active": "/project/city"
        }
      },
      {
        path: 'estate',
        name: '/project/estate',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate.vue'),
        children: [
          {
            path: ':id',
            name: '/project/estate/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'estate-model-:realEstateId',
        name: '/project/estate-model-[realEstateId]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-model-[realEstateId].vue'),
        /* no children */
      },
      {
        path: 'estate-model-:realEstateId-setting',
        name: '/project/estate-model-[realEstateId]-setting',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-model-[realEstateId]-setting.vue'),
        /* no children */
      },
      {
        path: 'estate-setting-:realEstateId',
        name: '/project/estate-setting-[realEstateId]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId].vue'),
        children: [
          {
            path: 'basic',
            name: '/project/estate-setting-[realEstateId]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/basic.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          },
          {
            path: 'imgs',
            name: '/project/estate-setting-[realEstateId]/imgs',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/imgs.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          },
          {
            path: 'keeper',
            name: '/project/estate-setting-[realEstateId]/keeper',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/keeper.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          },
          {
            path: 'kickback',
            name: '/project/estate-setting-[realEstateId]/kickback',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/kickback.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          },
          {
            path: 'setting',
            name: '/project/estate-setting-[realEstateId]/setting',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/setting.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          },
          {
            path: 'surround',
            name: '/project/estate-setting-[realEstateId]/surround',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/surround.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          },
          {
            path: 'tag',
            name: '/project/estate-setting-[realEstateId]/tag',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/tag.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          },
          {
            path: 'type',
            name: '/project/estate-setting-[realEstateId]/type',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/estate-setting-[realEstateId]/type.vue'),
            /* no children */
            meta: {
              "active": "/project/estate"
            }
          }
        ],
      },
      {
        path: 'keeper',
        name: '/project/keeper',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/keeper.vue'),
        children: [
          {
            path: ':id',
            name: '/project/keeper/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/keeper/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'keeper-setting-:housekeeperUserId',
        name: '/project/keeper-setting-[housekeeperUserId]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/keeper-setting-[housekeeperUserId].vue'),
        children: [
          {
            path: 'basic',
            name: '/project/keeper-setting-[housekeeperUserId]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/keeper-setting-[housekeeperUserId]/basic.vue'),
            /* no children */
            meta: {
              "active": "/project/keeper"
            }
          },
          {
            path: 'estate',
            name: '/project/keeper-setting-[housekeeperUserId]/estate',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/keeper-setting-[housekeeperUserId]/estate.vue'),
            /* no children */
            meta: {
              "active": "/project/keeper"
            }
          },
          {
            path: 'history',
            name: '/project/keeper-setting-[housekeeperUserId]/history',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/keeper-setting-[housekeeperUserId]/history.vue'),
            /* no children */
            meta: {
              "active": "/project/keeper"
            }
          },
          {
            path: 'user',
            name: '/project/keeper-setting-[housekeeperUserId]/user',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/keeper-setting-[housekeeperUserId]/user.vue'),
            /* no children */
            meta: {
              "active": "/project/keeper"
            }
          }
        ],
      },
      {
        path: 'organize',
        name: '/project/organize',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/organize.vue'),
        children: [
          {
            path: ':id',
            name: '/project/organize/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/project/organize/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/reward',
    /* internal name: '/reward' */
    /* no component */
    children: [
      {
        path: 'details',
        name: '/reward/details',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/reward/details.vue'),
        /* no children */
      },
      {
        path: 'details/:id',
        name: '/reward/details.[id]',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/reward/details.[id].vue'),
        children: [
          {
            path: 'basic',
            name: '/reward/details.[id]/basic',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/reward/details.[id]/basic.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'register',
        name: '/reward/register',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/reward/register.vue'),
        children: [
          {
            path: ':id',
            name: '/reward/register/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/reward/register/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/setting',
    /* internal name: '/setting' */
    /* no component */
    children: [
      {
        path: 'dept',
        name: '/setting/dept',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/dept.vue'),
        children: [
          {
            path: ':id()_:action',
            name: '/setting/dept/[id]_[action]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/dept/[id]_[action].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'dict',
        name: '/setting/dict',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/dict.vue'),
        children: [
          {
            path: ':id',
            name: '/setting/dict/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/dict/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'menu',
        name: '/setting/menu',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/menu.vue'),
        children: [
          {
            path: ':id()_:action',
            name: '/setting/menu/[id]_[action]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/menu/[id]_[action].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'position',
        name: '/setting/position',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/position.vue'),
        children: [
          {
            path: ':id()_:action',
            name: '/setting/position/[id]_[action]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/position/[id]_[action].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'role',
        name: '/setting/role',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/role.vue'),
        children: [
          {
            path: ':id()_:action',
            name: '/setting/role/[id]_[action]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/role/[id]_[action].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'settle',
        name: '/setting/settle',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/settle.vue'),
        /* no children */
      },
      {
        path: 'sign',
        name: '/setting/sign',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/sign.vue'),
        /* no children */
      },
      {
        path: 'user',
        name: '/setting/user',
        component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/user.vue'),
        children: [
          {
            path: ':id',
            name: '/setting/user/[id]',
            component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/setting/user/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/user',
    name: '/user',
    component: () => import('/root/workspace/zheLiSettleDownWeb_NXTJ/src/pages/user.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}


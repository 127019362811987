import { createRouter, createWebHistory } from 'vue-router/auto'
import { routes, handleHotUpdate } from 'vue-router/auto-routes'
import { setupLayouts } from 'virtual:generated-layouts'
import Cookies from 'js-cookie'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: setupLayouts(routes)
})

if (import.meta.hot) {
  handleHotUpdate(router)
}

router.beforeEach((to, from, next) => {
  if (to.name !== '/login' && !Cookies.get(USERID)) {
    ElMessage.warning({ message: '登录失效，请重新登录' })
    next({ name: '/login' })
  }
  next()
})

export default router

ElDialog.props['destroy-on-close'] = { type: Boolean, default: false }

ElInput.props.placeholder = { type: String, default: '请输入内容' }
ElInputNumber.props.controls = { type: Boolean, default: false }

ElTable.props.stripe = { type: Boolean, default: true }
ElTable.props.border = { type: Boolean, default: true }
ElTableColumn.props['show-overflow-tooltip'] = { type: Boolean, default: true }

ElPagination.props.background = { type: Boolean, default: true }
ElPagination.props.layout = { type: String, default: 'total, sizes, prev, pager, next, jumper' }
ElPagination.props['page-sizes'] = { type: Object, default: [20, 50, 100] }

ElDatePicker.props.placeholder = { type: String, default: '请选择日期' }
ElDatePicker.props['start-placeholder'] = { type: String, default: '开始日期' }
ElDatePicker.props['end-placeholder'] = { type: String, default: '结束日期' }

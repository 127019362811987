export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'
export const AUTHORIZATION = 'ZLAJ_AUTHORIZATION'
export const USERID = 'ZLAJ_USERID'
export const UPLOADSPLIT = '_zlaj_'

export const CONTRACT_DATA = Symbol('CONTRACT_DATA')
export const CONTRACT_EXECUTE = Symbol('CONTRACT_EXECUTE')
export const INVITE_DETAIL_DATA = Symbol('INVITE_DETAIL_DATA')

export const REFUND_DATA = Symbol('REFUND_DATA')
export const REFUND_EXECUTE = Symbol('REFUND_EXECUTE')

export const SETTLEMENT_DATA = Symbol('SETTLEMENT_DATA')
export const SETTLEMENT_EXECUTE = Symbol('SETTLEMENT_EXECUTE')

export const DETAIL_DATA = Symbol('DETAIL_DATA')
